const coupons = {
  coupons: [],
  loading: false,
  error: "",
  redirect: false,
};

const Coupons = (state = coupons, action) => {
  switch (action.type) {
    case "COUPONS_REQUEST":
      return {
        ...state,
        redirect: false,
        loading: true,
      };
    case "COUPONS_SUCCESS":
      return {
        ...state,
        loading: false,
        coupons: action.coupons,
      };
    case "COUPONS_FAIL":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "COUPON_ADD_REQUEST":
      return {
        ...state,
        loading: true,
        redirect: false,

      };
    case "COUPON_ADD_SUCCESS":
      return {
        ...state,
        loading: false,
        coupons: [...state.coupons, action.coupons],
        redirect: true,
      };
    case "COUPON_ADD_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
        redirect: false,
      };
    case "COUPON_DELETE_REQUEST":
      return {
        ...state,
        redirect: false,
        loading: true,
      };
    case "COUPON_DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
        coupons: state.coupons.filter(
          (coupon) => coupon._id !== action.coupons._id
        ),
        error: "",
      };
    case "COUPON_DELETE_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
        redirect: false,
      };
    case "COUPON_UPDATE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "COUPON_UPDATE_SUCCESS":
      return {
        ...state,
        loading: false,
        coupons: state.coupons.map((coupons) =>
          coupons.id === action.coupons.id ? action.coupons : coupons
        ),
        redirect: true,
      };
    case "COUPON_UPDATE_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "REDIRECT_FALSE":
      return {
        ...state,
        redirect: false,
      };

    default:
      return state;
  }
};

export default Coupons;
