import { React, useEffect, useState } from "react";
import InputField from "../components/InputFields/InputField";
import PulseLoader from "../components/PulseLoader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { updateCoupons } from "../actions/couponActions";
import moment from "moment";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";

const cstInitialData = {
  couponCode: "",
  expiryDate: "",
  discount: "",
  products: [],
};

const CouponsEditForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { products } = useSelector((state) => state?.Product);
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [filterProduct, setFilterProduct] = useState([]);
  const [seletectRows, setSelectedRows] = useState();
  const [coupon, setCoupon] = useState(cstInitialData);
  const [startDate, setStartDate] = useState(new Date());

  const { coupons, error, redirect, loading } = useSelector(
    (state) => state?.Coupons
  );

  const handleChange = (e) => {
    setQuery(e.target.value);
    setSearchParams({ query: e.target.value });
  };

  useEffect(() => {
    if (query) {
      const newProducts = products.filter((product) => {
        return (
          product?.reference.toLowerCase().includes(query.toLowerCase()) ||
          product?.category.name.toLowerCase().includes(query.toLowerCase())
        );
      });
      setFilterProduct(newProducts);
    } else {
      setFilterProduct(products);
    }
  }, [query, products]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCoupon({ ...coupon, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    coupon.expiryDate = new Date((coupon.expiryDate = new Date(startDate)));
    coupon.products = seletectRows;

    if (coupon.expiryDate < new Date()) {
      toast.error("Please select a valid date");
      return;
    }

    if (coupon?.products?.length === 0)
      return toast.error("Please select atleast one product");

    if (
      coupon?.discount === "" ||
      coupon?.discount === undefined ||
      coupon?.couponCode === "" ||
      coupon?.couponCode === undefined ||
      coupon?.expiryDate === "" ||
      coupon?.expiryDate === undefined
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    dispatch(updateCoupons(coupon?.id, coupon));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (redirect) {
      navigate("/coupons");
    }
    dispatch({ type: "REDIRECT_FALSE" });
    //  eslint-disable-next-line
  }, [redirect]);

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          onClick={(e) => {
            if (e.target.checked) {
              setSelectedRows(filterProduct?.map((i) => i?._id));
            } else {
              setSelectedRows([]);
            }
          }}
        />
      ),
      selector: (row) => row.checkbox,
    },
    {
      name: "Index",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Reference",
      selector: (row) => row.reference,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Stock",
      selector: (row) => row.stock,
      sortable: true,
    },
  ];

  useEffect(() => {
    const couponData = coupons?.find((coupon) => coupon?.id === +id);

    setSelectedRows(couponData?.products);

    setCoupon({
      id: couponData?._id,
      couponCode: couponData?.couponCode,
      // expiryDate: moment(couponData?.expiryDate).format("YYYY-MM-DD"),
      discount: couponData?.discount,
      products: couponData?.products?.map((i) => i),
    });

    setStartDate(new Date(moment(couponData?.expiryDate)));

    // eslint-disable-next-line
  }, [id, products]);

  const data = filterProduct?.map((product, index) => {
    return {
      checkbox: (
        <input
          type="checkbox"
          checked={seletectRows?.includes(product?._id)}
          onClick={() => {
            if (seletectRows?.includes(product?._id)) {
              setSelectedRows(seletectRows.filter((i) => i !== product?._id));
            } else {
              setSelectedRows([...seletectRows, product?._id]);
            }
          }}
        />
      ),
      prodId: product?._id,
      id: index,
      name: product?.name,
      category: product?.category ? product?.category?.name : "Uncategorized",
      price: product?.price,
      stock: product?.stock,
      reference: product?.reference,
    };
  });

  return (
    <>
      <div className="content-wrapper justify-content-start container-fluid">
        {loading ? (
          <PulseLoader />
        ) : (
          <>
            <div className="row p-5 justify-content-center">
              <div className="col-md-12">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Update Coupon</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <InputField
                            label="Coupon Code"
                            name="couponCode"
                            value={coupon?.couponCode}
                            onChange={handleInputChange}
                            type="text"
                            placeholder="Coupon Code"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <InputField
                            label="Discount"
                            name="discount"
                            value={coupon?.discount}
                            onChange={handleInputChange}
                            type="number"
                            placeholder="Discount %"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="mb-3">Expiry Date</label> <br />
                          <DatePicker
                            selected={startDate}
                            className="form-control"
                            minDate={new Date()}
                            onChange={(date) => setStartDate(date)}
                            showTimeInput
                          />
                        </div>
                      </div>
                      <hr />

                      <div className="col-md-12">
                        <h3 className="mt-3 mb-2">Select Product</h3>
                        <input
                          type="text"
                          name="search"
                          onChange={handleChange}
                          value={query}
                          className="form-control my-3"
                          placeholder="Search Product By Reference and category"
                        />
                        <DataTable
                          columns={columns}
                          data={data}
                          defaultSortAsc="true"
                          pagination
                          responsive="true"
                        />
                      </div>
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Update Coupon
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CouponsEditForm;
