const tax = {
  tax: [],
  message: "",
  loading: false,
  error: null,
};

const Tax = (state = tax, action) => {
  switch (action.type) {
    case "GET_TAX_REQUEST":
      return { ...state, loading: true };
    case "GET_TAX_SUCCESS":
      return { ...state, loading: false, tax: action.tax };
    case "GET_TAX_FAILURE":
      return { ...state, loading: false, error: action.error };
    case "ADD_TAX_REQUEST":
      return { ...state, loading: true };
    case "ADD_TAX_SUCCESS":
      return { ...state, loading: false, tax: [...state.tax, action.tax] };
    case "ADD_TAX_FAILURE":
      return { ...state, loading: false, error: action.error };
    case "DELETE_TAX_REQUEST":
      return { ...state, loading: true };
    case "DELETE_TAX_SUCCESS":
      return {
        ...state,
        loading: false,
        tax: state.tax.filter((tax) => tax._id !== action.id),
      };
    case "DELETE_TAX_FAILURE":
      return { ...state, loading: false, error: action.error };
    case "UPDATE_TAX_REQUEST":
      return { ...state, loading: true };
    case "UPDATE_TAX_SUCCESS":
      return {
        ...state,
        loading: false,
        tax: state.tax.map((tax) =>
          tax.id === action.tax.id ? action.tax : tax
        ),
      };
    case "UPDATE_TAX_FAILURE":
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
};

export default Tax;
