const loadState = () => {
  try {
    const serializedState = localStorage.getItem("AdminShopyBlue");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("AdminShopyBlue", serializedState);
  } catch (e) {
    console.log(e);
  }
};

const persistedState = loadState();

export { saveState, persistedState };
