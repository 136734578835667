import React, { useState, useEffect } from "react";
import "./MultiImage.css";
import axios from "axios";
import PulseLoader from "../PulseLoader";
import { toast } from "react-hot-toast";

const MultipleImageField = ({
  name,
  data,
  setFiles,
  setFileLoading,
  screen,
}) => {
  const [prevImages, setPrevImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const host = process.env.REACT_APP_API_HOST;

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setLoading(true);
    setFileLoading(true);

    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append("photos", files[i]);
    }
    axios
      .post(`https://api.asc925.com/api/upload`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setPrevImages((prevImages) => [...prevImages, ...res.data]);
        setFiles((prevImages) => [...prevImages, ...res.data]);
        setLoading(false);
        setFileLoading(false);
        toast.success("Files uploaded successfully");
      })
      .catch((err) => {
        setLoading(false);
        setFileLoading(false);
        toast.error("Files upload failed");
      });
  };

  const handleRemoveImage = (image, i) => {
    const updatedImages = [...prevImages];
    const imageUrl = image.split("/").pop();
    axios
      .post(`${host}/api/delete/${imageUrl}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        updatedImages.splice(i, 1);
        setPrevImages(updatedImages);
        toast.success("File deleted successfully");
      })
      .catch((err) => {
        toast.error("File deletion failed");
      });
  };

  useEffect(() => {
    if (data) {
      setPrevImages(data);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    setFiles(prevImages);
    // eslint-disable-next-line
  }, [prevImages]);

  return (
    <div className="image-uploader">
      <div className="mb-3">
        <label className="form-label">
          {screen === "large"
            ? "Upload For Large Screens"
            : screen === "small"
            ? "Upload For Small Screens"
            : "Upload Image"}
        </label>
        <input
          className="form-control drop-area"
          name={name}
          onChange={handleFileChange}
          type="file"
          accept="image/*, video/*"
          multiple
        />
      </div>
      <div className="image-preview">
        {loading ? (
          <div className="loaderImage">
            {" "}
            <PulseLoader />{" "}
          </div>
        ) : (
          <>
            {prevImages.map((image, index) => (
              <div key={index} className="image-preview-item">
                {image.split(".").pop() !== "mp4" ? (
                  <img src={image} alt={`Product ${index + 1}`} />
                ) : (
                  <video src={image} alt={`Video ${index + 1}`} autoPlay loop />
                )}
                <i
                  className="bx bx-x"
                  onClick={() => handleRemoveImage(image, index)}
                ></i>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default MultipleImageField;
