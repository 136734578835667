import React from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  RadialLinearScale,
  PointElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineController,
  LineElement,
} from "chart.js";
// Doughnut, PolarArea, Radar,
import { Bar, Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  RadialLinearScale,
  PointElement,
  LineElement
);

const Dashboard = () => {
  const { products } = useSelector((state) => state.Product);
  const { orders } = useSelector((state) => state.Order);
  const { categories } = useSelector((state) => state.Category);
  const { userList } = useSelector((state) => state.Auth);
  const productlengthcategory = categories.map((cat) => {
    return {
      name: cat.name,
      length: products.filter((prod) => prod.category._id === cat._id).length,
    };
  });

  const monthwiseorders = orders.map((order) => {
    return {
      month: new Date(order.createdAt).getMonth(),
      year: new Date(order.createdAt).getFullYear(),
      total: order.totalPrice,
    };
  });

  const getOrdersLength = (month, year) => {
    return monthwiseorders.filter(
      (order) => order.month === month && order.year === year
    ).length;
  };

  const dataforProducts = {
    labels: categories.map((cat) => cat.name),
    datasets: [
      {
        label: "Products",
        data: productlengthcategory.map((cat) => cat.length),
        backgroundColor: "#4c86c6",
        borderColor: "#4c86c6",
        borderWidth: 1,
      },
    ],
  };

  const pastSixMonths = new Array(6)
    .fill(0)
    .map((_, i) => {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      return date.toLocaleString("default", { month: "long" });
    })
    .reverse();

  const dataLineChart = {
    labels: pastSixMonths,
    datasets: [
      {
        label: "Orders",
        data: new Array(7)
          .fill(0)
          .map((_, i) => getOrdersLength(i + new Date().getMonth() - 5, 2023)),
        fill: false,
        borderColor: "#4c86c6",
        backgroundColor: "#4c86c6",
        tension: 0.1,
      },
    ],
  };

  const optionsLineChart = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const dataPieChart = {
    labels: ["Blocked", "Pending", "Approved", "Rejected"],
    datasets: [
      {
        label: "Users",
        data: [],
        backgroundColor: ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0"],
        hoverOffset: 4,
      },
    ],
  };

  const counts = userList.reduce((acc, user) => {
    const status = user.isApproved;
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  dataPieChart.datasets[0].data = [
    counts["Blocked"] || 0,
    counts["Pending"] || 0,
    counts["Approved"] || 0,
    counts["Rejected"] || 0,
  ];

  const userRegistrationRatio = userList.map((user) => {
    return {
      month: new Date(user.createdAt).getMonth(),
      year: new Date(user.createdAt).getFullYear(),
      status: user.isApproved,
    };
  });

  // user registered in last 6 months
  const getRegisteredUsersLength = (month, year) => {
    return userRegistrationRatio.filter(
      (user) => user.month === month && user.year === year
    ).length;
  };

  const data = {
    labels: pastSixMonths,
    datasets: [
      {
        label: "Users",
        data: new Array(7)
          .fill(0)
          .map((_, i) =>
            getRegisteredUsersLength(i + new Date().getMonth() - 5, 2023)
          ),
        fill: false,
        borderColor: "#4c86c6",
        backgroundColor: "#4c86c6",
        tension: 0.1,
      },
    ],
  };

  return (
    <div className="content-wrapper container-fluid">
      <div className="row">
        <h3 className="my-3">Shopy Blue Analytics</h3>

        <div className="col-md-6 mb-4">
          <div className="card p-5">
            <h3 className="text-center">Orders History</h3>
            <div className="card-body">
              <Line options={optionsLineChart} data={dataLineChart} />
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card p-5">
            <h3 className="text-center">Products by Categories</h3>
            <div className="card-body">
              <Bar data={dataforProducts} options={optionsLineChart} />
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-4">
          <div className="card p-5">
            <h3 className="text-center">Users Registered In Past 6 Months</h3>
            <div className="card-body">
              <Bar data={data} options={optionsLineChart} />
            </div>
          </div>
        </div>

        {/* <div className="col-md-6 mb-5">
          <div className="card p-5">
            <h3 className="text-center">Customers</h3>
            <div className="card-body">
              <Pie data={dataPieChart} options={optionsPieChart} />
            </div>
          </div>
        </div> */}
        {/* <div className="col-md-4 my-5">
          <Doughnut data={data} options={options} />
        </div> */}
        {/* <div className="col-md-4 my-5">
          <PolarArea data={data} />
        </div> */}
        {/* <div className="col-md-4 my-5">
          <Radar data={data} />
        </div>
        <div className="col-md-4 my-5">
          <Line options={optionsLineChart} data={dataLineChart} />
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
