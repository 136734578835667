import { React } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import PulseLoader from "../components/PulseLoader";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
const { deleteCoupons } = require("../actions/couponActions");

const Coupons = () => {
  const dispatch = useDispatch();
  const { coupons } = useSelector((state) => state.Coupons);
  const { products } = useSelector((state) => state.Product);
  const Loading = useSelector((state) => state.Coupons.loading);
  const handleDelete = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteCoupons(id));
    }
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="dropdown">
          <button
            type="button"
            className="btn p-0 dropdown-toggle hide-arrow"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="bx bx-dots-vertical-rounded"></i>
          </button>
          <div className="dropdown-menu">
            <Link
              className="dropdown-item"
              to={{
                pathname: `/coupon/edit/${row.id}`,
              }}
            >
              <i className="bx bx-edit-alt me-1"></i> Edit
            </Link>
            <div
              className="dropdown-item"
              onClick={() => {
                handleDelete(row.catId);
              }}
            >
              <i className="bx bx-trash me-1"></i> Delete
            </div>
          </div>
        </div>
      ),
      width: "100px",
    },
    {
      name: "Coupon Code",
      selector: (row) => row.couponCode,
      sortable: true,
      width: "200px",

    },
    {
      name: "Expiry Date",
      selector: (row) => row.expiryDate,
      width: "300px",
    },
    {
      name: "Discount",
      selector: (row) => row.discount,
      width: "100px",
    },
    {
      name: "Products",
      selector: (row) => row.products,
      width: "200px",
    },
  ];

  const data = coupons?.map((coupon) => {
    return {
      catId: coupon?._id,
      id: coupon?.id,
      couponCode: coupon?.couponCode,
      expiryDate: moment(coupon?.expiryDate).calendar(),
      discount: `${coupon?.discount}%`,
      products: products
        // eslint-disable-next-line array-callback-return
        .map((product) => {
          if (coupon?.products?.includes(product?._id)) {
            return product?.barCode;
          }
        })
        .filter((product) => product !== undefined)
        .join(", "),
    };
  });

  return (
    <>
      <div className="content-wrapper container-fluid">
        {Loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5">
            <div className="card">
              <div className="d-flex justify-content-between">
                <h5 className="card-header">All Coupons</h5>
                <div>
                  <Link
                    to="/coupon/add"
                    className="btn btn-outline-primary my-3"
                  >
                    <span className="tf-icons bx bx-plus-circle"></span>&nbsp;
                    Add Coupons
                  </Link>
                </div>
              </div>
              <div className="table-responsive text-nowrap">
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortAsc="true"
                  pagination
                  responsive="true"
                />
              </div>
            </div>
          </div>
        )}

        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
};

export default Coupons;
