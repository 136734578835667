import { React, useState, useEffect } from "react";
import InputField from "../components/InputFields/InputField";
import PulseLoader from "../components/PulseLoader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import MultipleImageField from "../components/InputFields/MultipleImageField";

const {
  addBanner,
  updateBanner,
  setRedirecting,
} = require("../actions/bannerActions");

const BannerForm = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { FormName, Editable } = props;
  const { banners, redirect, loading } = useSelector((state) => state.Banner);
  const [banner, setBan] = useState({});
  const [Banner, setBanner] = useState({
    url: "",
    photo: [],
    photoSmall: [],
    title: "",
    subtitle: "",
    buttonText: "",
  });

  const [fileLoading, setFileLoading] = useState(false);
  const [fileLoadingSm, setFileLoadingSm] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesSm, setFilesSm] = useState([]);

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line
      const bann = banners.find((banner) => banner.id == id);
      if (bann) {
        setBan(bann);
        setBanner({
          url: bann.url,
          page: bann.page,
          photo: bann.photo,
          photoSmall: bann.photoSmall,
          title: bann.title,
          subtitle: bann.subtitle,
          buttonText: bann.buttonText,
        });
      }
    }
    // eslint-disable-next-line
  }, [id, banners]);

  useEffect(() => {
    if (redirect) {
      navigate("/banners");
    }
    dispatch(setRedirecting());
    // eslint-disable-next-line
  }, [redirect]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBanner({ ...Banner, [name]: value });
  };

  const handleAddBanner = (e) => {
    e.preventDefault();

    const { url, title, subtitle, buttonText } = Banner;
    if (url === "" || title === "" || subtitle === "" || buttonText === "")
      return toast.error("Please fill all the fields");

    Banner.photo = files;
    Banner.photoSmall = filesSm;

    if (Banner.photo.length === 0 || Banner.photoSmall.length === 0)
      return toast.error("Please upload images");

    dispatch(addBanner(Banner));

    // toast.success('Banner added');
  };

  const handleUpdateBanner = (e) => {
    e.preventDefault();
    Banner.photo = files;
    Banner.photoSmall = filesSm;
    dispatch(updateBanner(banner._id, Banner));
  };

  return (
    <>
      <div className="content-wrapper container">
        {loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5 justify-content-center">
            <div className="col-md-5">
              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">{FormName}</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <InputField
                            label="Title"
                            name="title"
                            value={Banner.title}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Title"
                          />
                        </div>
                        <div className="mb-3">
                          <InputField
                            label="SubTitle"
                            name="subtitle"
                            value={Banner.subtitle}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="SubTitle"
                          />
                        </div>
                        <div className="mb-3">
                          <InputField
                            label="Button Text"
                            name="buttonText"
                            value={Banner.buttonText}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Title"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <InputField
                            label="URL"
                            name="url"
                            value={Banner.url}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="https://dev.shopyblue.com/shop"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <MultipleImageField
                            name="photos"
                            setFiles={setFiles}
                            data={Banner.photo}
                            setFileLoading={setFileLoading}
                            screen="large"
                          />
                        </div>

                        <div className="mb-3">
                          <MultipleImageField
                            name="photoSmall"
                            setFiles={setFilesSm}
                            data={Banner.photoSmall}
                            setFileLoading={setFileLoadingSm}
                            screen="small"
                          />
                        </div>
                      </div>
                    </div>

                    {Editable === "true" ? (
                      <button
                        type="submit"
                        onClick={handleUpdateBanner}
                        className="btn btn-primary"
                        disabled={fileLoading || fileLoadingSm ? true : false}
                      >
                        Update Banner
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={handleAddBanner}
                        className="btn btn-primary"
                        disabled={fileLoading || fileLoadingSm ? true : false}
                      >
                        Save
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
};

export default BannerForm;
