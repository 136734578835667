import { React } from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import ProtectedRoute from "./components/ProtectedRoute";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SidebarLayout from "./components/Sidebar/SidebarLayout";
import Login from "./views/Login";
import PageNotFound from "./views/PageNotFound";
// import ProductForm from './views/ProductForm';
import "react-datepicker/dist/react-datepicker.css";

import Product from "./views/Product";
import Otp from "./components/OTP/Otp";
import Category from "./views/Category";
import CategoryForm from "./views/CategoryForm";
import Dashboard from "./views/Dashboard";
import Tier from "./views/Tier";
import Order from "./views/Order";
import OrderDeatil from "./views/OrderDeatil";
import Customers from "./views/Customers";
import TierForm from "./views/TierForm";
import CustomerForm from "./views/CustomerForm";
import SubCategory from "./views/SubCategory";
import SubCategoryForm from "./views/SubCategoryForm";
import CustomersRequest from "./views/CustomersRequest";
import Tag from "./views/Tag";
import TagForm from "./views/TagForm";
import ProductEditForm from "./views/ProductEditForm";
import ProductAddForm from "./views/ProductAddForm";
import CustomerPdf from "./views/CustomerPdf";
import Tax from "./views/Tax";
import TaxForm from "./views/TaxForm";
import Coupons from "./views/Coupons";
import CouponsAddForm from "./views/CouponsAddForm";
import CouponsEditForm from "./views/CouponsEditForm";
import BannerForm from "./views/BannerForm";
import Banner from "./views/Banner";

function App() {
  return (
    <>
      <Router>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <Routes>
              <Route element={<SidebarLayout />}>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/categories"
                  element={
                    <ProtectedRoute>
                      <Category />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/categories/add-category"
                  element={
                    <ProtectedRoute>
                      <CategoryForm FormName="Add Category" Editable="false" />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/categories/edit-category/:id"
                  element={
                    <ProtectedRoute>
                      <CategoryForm FormName="Edit Category" Editable="true" />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/sub-categories"
                  element={
                    <ProtectedRoute>
                      <SubCategory />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/sub-categories/add-subcategory"
                  element={
                    <ProtectedRoute>
                      <SubCategoryForm
                        FormName="Add Sub Category"
                        Editable="false"
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/sub-categories/edit-subcategory/:id"
                  element={
                    <ProtectedRoute>
                      <SubCategoryForm
                        FormName="Edit Sub Category"
                        Editable="true"
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tier"
                  element={
                    <ProtectedRoute>
                      <Tier />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tier/add-tier"
                  element={
                    <ProtectedRoute>
                      <TierForm FormName="Add Tier" Editable="false" />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tier/edit-tier/:id"
                  element={
                    <ProtectedRoute>
                      <TierForm FormName="Edit Tier" Editable="true" />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tag"
                  element={
                    <ProtectedRoute>
                      <Tag />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tag/add-tag"
                  element={
                    <ProtectedRoute>
                      <TagForm FormName="Add Tag" Editable="false" />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tag/edit-tag/:id"
                  element={
                    <ProtectedRoute>
                      <TagForm FormName="Edit Tag" Editable="true" />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/products"
                  element={
                    <ProtectedRoute>
                      <Product />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tax"
                  element={
                    <ProtectedRoute>
                      <Tax />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tax/add-tax"
                  element={
                    <ProtectedRoute>
                      <TaxForm />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/tax/edit-tax/:id"
                  element={
                    <ProtectedRoute>
                      <TaxForm Editable="true" />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/orders/:company"
                  element={
                    <ProtectedRoute>
                      <Order />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/order-details/:id"
                  element={
                    <ProtectedRoute>
                      <OrderDeatil />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/coupons"
                  element={
                    <ProtectedRoute>
                      <Coupons />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/coupon/add"
                  element={
                    <ProtectedRoute>
                      <CouponsAddForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/coupon/edit/:id"
                  element={
                    <ProtectedRoute>
                      <CouponsEditForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/products/add-product"
                  element={
                    <ProtectedRoute>
                      <ProductAddForm FormName="Add Product" Editable="false" />
                    </ProtectedRoute>
                  }
                />

                {/* <Route path="/products/edit-product/:id" element={<ProtectedRoute>
                  <ProductForm FormName="Edit Product" Editable="true" />
                </ProtectedRoute>} /> */}

                <Route
                  path="/products/edit-product/:id"
                  element={
                    <ProtectedRoute>
                      <ProductEditForm
                        FormName="Edit Product"
                        Editable="true"
                      />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/customers"
                  element={
                    <ProtectedRoute>
                      <Customers type={"Customers"} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/customers/approve-cusotmer/:id"
                  element={
                    <ProtectedRoute>
                      <CustomerForm type={"Customers"} />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/customer/requests"
                  element={
                    <ProtectedRoute>
                      <CustomersRequest type={"Customers"} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/customer/downloadinfo/:id"
                  element={
                    <ProtectedRoute>
                      <CustomerPdf />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/banners"
                  element={
                    <ProtectedRoute>
                      <Banner />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/banners/add-banner"
                  element={
                    <ProtectedRoute>
                      <BannerForm FormName="Add Banner" Editable="false" />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/banners/edit-banner/:id"
                  element={
                    <ProtectedRoute>
                      <BannerForm FormName="Edit Banner" Editable="true" />
                    </ProtectedRoute>
                  }
                />
              </Route>

              <Route path="/login" element={<Login />} />
              <Route path="/verification/email/:id" element={<Otp />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
          <div className="layout-overlay layout-menu-toggle"></div>
        </div>
        <Toaster position="top-center" />
      </Router>
    </>
  );
}

export default App;
