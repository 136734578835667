import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../actions/authActions";

const Navbar = () => {
  const { user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  return (
    <>
      <header className="header">
        <div className="header__container">
          <div className="navbar-nav w-50">
            <div className="nav-item d-flex w-25">
              <img
                src="https://shopyblue.com/assets/img/logo/Shopy-blue.png"
                alt=""
                className=" img-fluid w-25"
              />
            </div>
          </div>
          <div className="nav-item navbar-dropdown dropdown-user dropdown">
            <a
              className="nav-link dropdown-toggle hide-arrow"
              href="/#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="avatar avatar-online rounded-circle bg-primary ">
                <span className="ms-2 text-white d-flex justify-content-center h-100 align-items-center font-size-base me-2">
                  {user && user?.name && user?.name[0].toUpperCase()}
                </span>
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <a className="dropdown-item" href="/#">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar  avatar-online rounded-circle bg-primary ">
                        <span className="ms-2 text-white d-flex justify-content-center h-100 align-items-center font-size-base me-2">
                          {user && user?.name && user?.name[0].toUpperCase()}
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <span className="fw-semibold d-block">
                        {user && user?.name && user?.name}
                      </span>
                      <small className="text-muted">Admin</small>
                    </div>
                  </div>
                </a>
              </li>
              {/* <li>
                                    <div className="dropdown-divider"></div>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/#">
                                        <i className="bx bx-user me-2"></i>
                                        <span className="align-middle">My Profile</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/#">
                                        <i className="bx bx-cog me-2"></i>
                                        <span className="align-middle">Settings</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/#">
                                        <span className="d-flex align-items-center align-middle">
                                            <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                                            <span className="flex-grow-1 align-middle">Billing</span>
                                            <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                                        </span>
                                    </a>
                                </li> */}
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => dispatch(userLogout())}
                >
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Log Out</span>
                </span>
              </li>
            </ul>
          </div>

          <div className="header__toggle">
            <i className="bx bx-menu" id="header-toggle"></i>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
