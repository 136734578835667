import { React, useState, useEffect } from "react";
import InputField from "../components/InputFields/InputField";
import PulseLoader from "../components/PulseLoader";
// import ImageField from '../components/InputFields/ImageField';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import MultipleImageField from "../components/InputFields/MultipleImageField";

const { addCategory, updateCategory } = require("../actions/categoryActions");
const CategoryForm = (props) => {
  const { id } = useParams();
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const { FormName, Editable } = props;
  const { Loading } = useSelector((state) => state.Category);
  const { categories } = useSelector((state) => state.Category);
  const [category, setCat] = useState({});
  const [Category, setCategory] = useState({
    name: "",
    photo: [],
  });
  const [fileLoading, setFileLoading] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line
      const cat = categories.find((category) => category.id == id);
      if (cat) {
        setCat(cat);
        setCategory({
          name: cat.name,
          photo: cat.photo,
        });
      }
    }

    // eslint-disable-next-line
  }, [id, categories]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...Category, [name]: value });
  };

  // const handleFileChange = (e) => {
  //   const photo = e.target.files[0];
  //   setCategory({ ...Category, photo });
  // }

  const handleAddCategory = (e) => {
    e.preventDefault();
    if (Category.name === "") {
      toast.error("Please enter category name");
      return;
    } else if (Category.name !== "") {
      Category.photo = files;
      dispatch(addCategory(Category));
      redirect("/categories");
    }
    // toast.success('Category added');
  };

  const handleUpdateCategory = (e) => {
    e.preventDefault();
    Category.photo = files;
    dispatch(updateCategory(category._id, Category));
    redirect("/categories");
  };

  return (
    <>
      <div className="content-wrapper container-fluid">
        {Loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5 justify-content-center">
            <div className="col-md-5">
              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">{FormName}</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <InputField
                            label="Name"
                            name="name"
                            value={Category.name}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Name here..."
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          {/* <ImageField label="Image" name='photo' onChange={handleFileChange} required={true} type='file' placeholder="Image here..." /> */}
                          <MultipleImageField
                            name="photos"
                            setFiles={setFiles}
                            data={Category.photo}
                            setFileLoading={setFileLoading}
                          />
                        </div>
                      </div>
                    </div>

                    {Editable === "true" ? (
                      <button
                        type="submit"
                        onClick={handleUpdateCategory}
                        className="btn btn-primary"
                        disabled={fileLoading ? true : false}
                      >
                        Update Category
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={handleAddCategory}
                        className="btn btn-primary"
                        disabled={fileLoading ? true : false}
                      >
                        Save
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
};

export default CategoryForm;
