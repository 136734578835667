import { React } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import PulseLoader from "../components/PulseLoader";
import { useSelector } from "react-redux";
// import { deleteTax } from "../actions/taxActions";

const Tax = () => {
  // const dispatch = useDispatch();
  const { tax } = useSelector((state) => state.Tax);
  const Loading = useSelector((state) => state.Tag.loading);

  // const handleDelete = (id) => {
  //   if (window.confirm("Are you sure?")) {
  //     dispatch(deleteTax(id));
  //   }
  // };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="dropdown">
          <button
            type="button"
            className="btn p-0 dropdown-toggle hide-arrow"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="bx bx-dots-vertical-rounded"></i>
          </button>
          <div className="dropdown-menu">
            <Link
              className="dropdown-item"
              to={{
                pathname: `/tax/edit-tax/${row.id}`,
              }}
            >
              <i className="bx bx-edit-alt me-1"></i> Edit
            </Link>
            {/* <div
              className="dropdown-item"
              onClick={() => {
                handleDelete(row.tagId);
              }}
            >
              <i className="bx bx-trash me-1"></i> Delete
            </div> */}
          </div>
        </div>
      ),
    },
    {
      name: "Tax %",
      selector: (row) => row.tagName,
      sortable: true,
    },
  ];

  const data = tax?.map((tag) => {
    return {
      tagId: tag._id,
      id: tag.id,
      tagName: tag.tax,
    };
  });

  return (
    <>
      <div className="content-wrapper container-fluid">
        {Loading ? (
          <PulseLoader />
        ) : (
          <>
            <div className="row px-5 py-3">
              <div className="card">
                <div className="d-flex justify-content-between">
                  <h5 className="card-header">Tax</h5>
                  {!tax?.length >= 1 && (
                    <div>
                      <Link
                        to="/tax/add-tax"
                        className="btn btn-outline-primary my-3"
                      >
                        <span className="tf-icons bx bx-plus-circle"></span>
                        &nbsp; Add Tax
                      </Link>
                    </div>
                  )}
                </div>
                <div className="table-responsive text-nowrap">
                  <DataTable
                    columns={columns}
                    data={data}
                    defaultSortAsc="true"
                    pagination
                    responsive="true"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
};

export default Tax;
