import { React } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import PulseLoader from "../components/PulseLoader";

import { useSelector, useDispatch } from "react-redux";
import { deleteBanner } from "../actions/bannerActions";

const Banner = () => {
  const dispatch = useDispatch();
  const { banners, loading } = useSelector((state) => state?.Banner);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteBanner(id));
    }
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.bannID,
      sortable: true,
      width: "70px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="dropdown">
          <button
            type="button"
            className="btn p-0 dropdown-toggle hide-arrow"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="bx bx-dots-vertical-rounded"></i>
          </button>
          <div className="dropdown-menu">
            <Link
              className="dropdown-item"
              to={{
                pathname: `/banners/edit-banner/${row.bannID}`,
              }}
            >
              <i className="bx bx-edit-alt me-1"></i> Edit
            </Link>
            <div
              className="dropdown-item"
              onClick={() => {
                handleDelete(row.id);
              }}
            >
              <i className="bx bx-trash me-1"></i> Delete
            </div>
          </div>
        </div>
      ),
      width: "100px",
    },
    {
      name: "For Desktop",
      selector: (row) => row.photo,
    },
    {
      name: "For Mobile",
      selector: (row) => row.photoSmall,
    },
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Sub Title",
      selector: (row) => row.subtitle,
    },
    {
      name: "URL",
      selector: (row) => row.url,
      sortable: true,
    },
  ];

  const data = banners?.map((banner) => {
    return {
      bannID: banner?.id,
      id: banner?._id,
      page: banner?.page,
      photo: (
        <img
          src={banner?.photo[0]}
          alt={banner?.name}
          className="img-fluid"
          width="100"
        />
      ),
      photoSmall: (
        <img
          src={
            banner && banner?.photoSmall[0] && banner?.photoSmall[0]
              ? banner?.photoSmall[0]
              : banner.photo[0]
          }
          alt={banner?.name}
          className="img-fluid"
          width="100"
        />
      ),
      title: banner?.title,
      subtitle: banner?.subtitle,
      url: banner?.url,
    };
  });

  return (
    <>
      <div className="content-wrapper container">
        {loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5">
            <div className="card">
              <div className="d-flex justify-content-between">
                <h5 className="card-header">All Banners</h5>
                <div>
                  <Link
                    to="/banners/add-banner"
                    className="btn btn-outline-primary my-3"
                  >
                    <span className="tf-icons bx bx-plus-circle"></span>&nbsp;
                    Add Banner
                  </Link>
                </div>
              </div>
              <div className="table-responsive text-nowrap">
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortAsc="true"
                  pagination
                  responsive="true"
                />
              </div>
            </div>
          </div>
        )}
        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
};

export default Banner;
