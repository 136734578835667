import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Modal from "../utils/CustomModal";
import BillingInfoModal from "./BillingInfoModal";
import moment from "moment";

const OrderDeatil = () => {
  const { id } = useParams();
  const orders = useSelector((state) => state?.Order?.orders);
  const order = orders.find((order) => order?._id === id);
  const modalRef = useRef(null);
  const { openModal, closeModal } = Modal(modalRef);
  return (
    <>
      <div className="content-wrapper container-fluid">
        <div className="card mb-3">
          <div className="card-body">
            <h2 className="card-title d-flex justify-content-between">
              <span>Order Details</span>
              <button className="btn btn-primary" onClick={openModal}>
                Billing Info
              </button>
            </h2>
            <div className="row">
              <div className="col-md-6">
                <p>
                  <b>Name : </b> {order?.shippingInfo?.name}
                </p>
                <p>
                  <b>Email : </b> {order?.shippingInfo?.email}
                </p>
                <p>
                  <b>Phone : </b> {order?.shippingInfo?.phone}
                </p>
                <p>
                  <b>City : </b> {order?.shippingInfo?.city}
                </p>
                <p>
                  <b>Country : </b> {order?.shippingInfo?.country}
                </p>
                <p>
                  <b>Zip Code : </b> {order?.shippingInfo?.postalCode}
                </p>
                <p>
                  <b>Address : </b> {order?.shippingInfo?.address}
                </p>
                <p>
                  <b>Location : </b>{" "}
                  {order?.isResidential ? "Residential" : "Commercial"}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <b>Payment Method : </b> Card
                </p>

                <p>
                  <b>Shipping Price : </b> {order?.shippingPrice.toFixed(2)}
                </p>

                <p>
                  <b>Payment Status : </b>
                  {order?.isPaid === true ? "Paid" : "Pending"}
                </p>
                <p>
                  <b>Order Status : </b>
                  {order?.status}
                </p>

                <p>
                  <b>Service Code:</b> {order?.serviceCode}
                </p>
                <p>{order?.paymentIntentSecret}</p>

                <p>
                  <b>Order Note : </b>{" "}
                  {order?.orderNote ? order?.orderNote : "N/A"}
                </p>
              </div>

              <div className="col-md-12 my-5 d-flex gap-4 flex-wrap">
                <p>
                  <b>Order Date : </b> {moment(order?.createdAt).calendar()}
                </p>
                {order?.subTotal > 0 && (
                  <p>
                    <b>Sub Total : </b> ${order?.subTotal}
                  </p>
                )}
                {order?.subTotal > 0 && (
                  <p>
                    <b>Tax : </b> ${((order?.subTotal / 100) * 8.25).toFixed(2)}
                  </p>
                )}

                {order?.totalDiscount > 0 && (
                  <p>
                    <b>Discount : </b> ${order?.totalDiscount}
                  </p>
                )}
                {order?.couponDiscountPrice > 0 && (
                  <p>
                    <b>Coupon Discount : </b> ${order?.couponDiscountPrice}
                  </p>
                )}

                <p>
                  <b>Total Amount : </b> ${order?.itemsPrice.toFixed(2)}
                </p>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {order?.orderItems.map((item) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <img
                            src={item.image}
                            alt={item.name}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </td>
                        <td>
                          <div className="d-flex gap-4">
                            <span>{item.name}</span>
                            <br />
                            {item?.ringSize && item.ringSize !== "" && (
                              <span>
                                Ring Size : <b>{item.ringSize}</b>
                              </span>
                            )}
                          </div>
                        </td>
                        <td>{item.qty}</td>
                        <td>{item.price}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <BillingInfoModal
        modalRef={modalRef}
        info={order?.billingInfo}
        closeModal={closeModal}
      />
    </>
  );
};

export default OrderDeatil;
