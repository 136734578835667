import React from "react";

const BillingInfoModal = ({ modalRef, closeModal, info }) => {
  return (
    <div
      className="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      ref={modalRef}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Billing Info
            </h5>
            <button
              type="button"
              className="close btn btn-primary"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                closeModal();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      <b>Name : </b> {info?.name}
                    </p>
                    <p>
                      <b>Email : </b> {info?.email}
                    </p>
                    <p>
                      <b>Phone : </b> {info?.phone}
                    </p>
                    <p>
                      <b>City : </b> {info?.city}
                    </p>
                    <p>
                      <b>Country : </b> {info?.country}
                    </p>
                    <p>
                      <b>Zip Code : </b> {info?.postalCode}
                    </p>
                    <p>
                      <b>Address : </b> {info?.address}
                    </p>
                    <p>
                      <b>Location : </b>{" "}
                      {info?.isResidential ? "Residential" : "Commercial"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingInfoModal;
