const initialState = {
  loading: false,
  banners: [],
  error: "",
  redirect: false,
};

const Banner = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BANNERS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_BANNERS_SUCCESS":
      return {
        ...state,
        loading: false,
        banners: action.banners,
      };
    case "GET_BANNERS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "ADD_BANNER_REQUEST":
      return {
        ...state,
        loading: true,
        redirect: false,
      };
    case "ADD_BANNER_SUCCESS":
      return {
        ...state,
        loading: false,
        banners: [...state.banners, action.banner],
        redirect: true,
      };
    case "ADD_BANNER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
        redirect: false,
      };
    case "DELETE_BANNER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "DELETE_BANNER_SUCCESS":
      return {
        ...state,
        loading: false,
        banners: state.banners.filter((banner) => banner._id !== action.id),
      };
    case "DELETE_BANNER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "UPDATE_BANNER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_BANNER_SUCCESS":
      return {
        ...state,
        loading: false,
        banners: state.banners.map((banner) =>
          banner.id === action.banner.id ? action.banner : banner
        ),
        redirect: true,
      };
    case "UPDATE_BANNER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "SET_REDIRECTING":
      return {
        ...state,
        redirect: false,
      };
    default:
      return state;
  }
};

export default Banner;
