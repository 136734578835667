import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers/index";
import { persistedState } from "../utils/persistor";

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export const persistor = store;
