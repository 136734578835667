import { React, useState, useEffect } from "react";
import InputField from "../components/InputFields/InputField";
import PulseLoader from "../components/PulseLoader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { addTax,updateTax } from "../actions/taxActions";

const TaxForm = (props) => {
  const { id } = useParams();
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const { FormName, Editable } = props;

  const { loading, tax } = useSelector((state) => state.Tax);
  const [tie, setTie] = useState({});
  const [Tax, setTax] = useState({
    tax: "",
  });

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line
      var updatedTax = tax.find((tag) => tag.id == id);
      if (updatedTax) {
        setTie(updatedTax);
        setTax({
          tax: updatedTax.tax,
        });
      }
    } else {
      if (tax.length >= 1) {
        redirect("/tax");
        toast.error("You can't add more than one tax");
      }
    }

    // eslint-disable-next-line
  }, [id, tax]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTax({ ...Tax, [name]: value });
  };

  const handleAddTax = (e) => {
    e.preventDefault();
    if (Tax.tagName === "") {
      toast.error("Please enter Tax name");
      return;
    } else if (Tax.retailerValue === "") {
      toast.error("Please enter retailerValue");
      return;
    } else if (Tax.dropshipperValue === "") {
      toast.error("Please enter dropshipperValue");
      return;
    } else if (
      Tax.retailerValue !== "" &&
      Tax.dropshipperValue !== "" &&
      Tax.tagName !== ""
    ) {
      dispatch(addTax(Tax));
      redirect("/tax");
    }
  };

  const handleUpdateTax = (e) => {
    e.preventDefault();
    dispatch(updateTax(tie._id, Tax));
    redirect("/tax");
  };

  return (
    <>
      <div className="content-wrapper container-fluid">
        {loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5 justify-content-center">
            <div className="col-md-6">
              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">{FormName}</h5>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <InputField
                            label="Tax %"
                            name="tax"
                            value={Tax.tax}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Tax Percentage Here"
                          />
                        </div>
                      </div>
                    </div>

                    {Editable === "true" ? (
                      <button
                        type="submit"
                        onClick={handleUpdateTax}
                        className="btn btn-primary"
                      >
                        Update Tax
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={handleAddTax}
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
};

export default TaxForm;
