import { React, useEffect, useState } from "react";
import InputField from "../components/InputFields/InputField";
import PulseLoader from "../components/PulseLoader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import Modal from "../utils/CustomModal";
import { GiBigDiamondRing } from "react-icons/gi";
import Select from "react-select";

const { updateProduct } = require("../actions/productActions");

const ProductEditForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const Loading = false;
  const [files, setFiles] = useState([]);
  const [fileLoading] = useState(false);
  const { categories } = useSelector((state) => state.Category);
  const { products } = useSelector((state) => state.Product);
  const { subCategories } = useSelector((state) => state.SubCategory);
  const { tags } = useSelector((state) => state.Tag);
  const [subCatOP, setSubCatOP] = useState([]);
  const product = products.find((pro) => pro.id === Number(id));
  const { redirect } = useSelector((state) => state.Product);
  const [size, setSize] = useState([]);
  const [findProduct, setFindProduct] = useState(null);
  const [quillValue] = useState(product?.description);
  const matchRing = /^(Rings|rings|Ring|ring|RING|RINGS)$/;
  const modalRef = useRef(null);

  const { openModal, closeModal } = Modal(modalRef);

  const [Product, setProduct] = useState({
    id: product?._id,
    name: product?.name,
    category: product?.category,
    subcategory: product?.subcategory,
    skuNumber: product?.skuNumber,
    price: product?.price,
    stock: product?.stock,
    reference: product?.reference,
    condition: product?.condition,
    specificReferences: product?.specificReferences,
    metalType: product?.metalType,
    stoneType: product?.stoneType,
    ringsSizeAndQuantity: product?.ringsSizeAndQuantity,
    tags: product?.tags,
    styleCode: product?.styleCode,
    barCode: product?.barCode,
    parityMetalColor: product?.parityMetalColor,
    rhodiumPlayed: product?.rhodiumPlayed,
    chainLength: product?.chainLength,
    chainType: product?.chainType,
    clasp: product?.clasp,
    ringSize: product?.ringSize,
    pheight: product?.pheight,
    pwidth: product?.pwidth,
    plength: product?.plength,
    backing: product?.backing,
    minCartTotalWeight: product?.minCartTotalWeight,
    averageColor: product?.averageColor,
    averageClarity: product?.averageClarity,
    settingType: product?.settingType,
    numberofDiamonds: product?.numberofDiamonds,
    photos: product?.images,
    discount: product?.discount,
    bestSeller: product?.bestSeller,
    centerStone: product?.centerStone,
    stoneColor: product?.stoneColor,
    mainStoneShape: product?.mainStoneShape,
    isPublished: product?.isPublished,
    isFeatured: product?.isFeatured,
    mostSearched: product?.mostSearched,
    relatedProducts: product?.relatedProducts,
  });

  const ringInitValue =
    product?.ringsSizeAndQuantity?.length > 0
      ? product?.ringsSizeAndQuantity
      : [{ ringSize: "", quantity: "" }];

  useEffect(() => {
    if (
      Product?.ringsSizeAndQuantity?.length === 0 ||
      Product?.ringSizeInitialValue === undefined
    ) {
      setSize(ringInitValue);
    } else {
      setSize(Product?.ringsSizeAndQuantity);
    }

    // eslint-disable-next-line
  }, []);

  const selectRingSize = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
  ];

  const handleRingSize = (e, index) => {
    const { value } = e;
    const list = [...size];
    list[index].ringSize = value;
    setSize(list);
  };


  const handleSizesChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...size];
    list[index][name] = value;
    setProduct({
      ...Product,
      ringsSizeAndQuantity: list,
    });
    setSize(list);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...Product, [name]: value });
  };

  const selectCatgeoryOptions = categories?.map((category) => {
    return { value: category._id, label: category?.name };
  });

  useEffect(() => {
    setFiles(Product?.photos);
  }, [Product?.photos]);


  const handleCategory = (e) => {
    setProduct({
      ...Product,
      category: e.value,
      subcategory: null,
    });
    const subCat = subCategories?.filter((subCategory) => {
      return subCategory.category._id === e.value;
    });

    const subCatOptions = subCat?.map((subCategory) => {
      return { value: subCategory._id, label: subCategory?.name };
    });
    const findCategory = categories?.find((cat) => cat._id === e.value);
    setFindProduct(findCategory);
    setSubCatOP(subCatOptions);
  };

  const handleSubCategory = (e) => {
    setProduct({
      ...Product,
      subcategory: e.value,
    });
  };

  useEffect(() => {
    const subCat = subCategories?.filter((subCategory) => {
      return subCategory.category._id === Product?.category._id;
    });

    const subCatOptions = subCat?.map((subCategory) => {
      return { value: subCategory._id, label: subCategory?.name };
    });

    setSubCatOP(subCatOptions);

    // eslint-disable-next-line
  }, []);

  const selectTag = tags?.map((tag) => {
    return { value: tag?._id, label: tag?.tagName };
  });

  const handleTag = (e) => {
    setProduct({
      ...Product,
      tags: e.value,
    });
  };

  const selectRelatedProducts = products?.map((product) => {
    return { value: product?._id, label: product?.barCode };
  });

  const handleRelatedProducts = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    setProduct({
      ...Product,
      relatedProducts: selectedValues,
    });
  };


  const selectMetalType = [
    { value: "Gold", label: "Gold" },
    { value: "Silver", label: "Silver" },
    { value: "Fashion", label: "Fashion" },
  ];

  const selectStoneType = [
    { value: "Natural Diamond", label: "Natural Diamond" },
    { value: "Simulated Diamond", label: "Simulated Diamond" },
    { value: "Lab Grown Diamond", label: "Lab Grown Diamond" },
    { value: "Moissanite", label: "Moissanite" },
    { value: "Cubic Zirconia", label: "Cubic Zirconia" },
    { value: "Gem Stones", label: "Gem Stones" },
  ];

  const handleMetalType = (e) => {
    setProduct({
      ...Product,
      metalType: e.value,
    });
  };

  const handleStoneType = (e) => {
    setProduct({
      ...Product,
      stoneType: e.value,
    });
  };

  const parityMetalColorOptions = [
    { value: "10k,White Gold", label: "10k White Gold" },
    { value: "14k,White Gold", label: "14k White Gold" },
    { value: "18k,White Gold", label: "18k White Gold" },
    { value: "10k,Yellow Gold", label: "10k Yellow Gold" },
    { value: "14k,Yellow Gold", label: "14k Yellow Gold" },
    { value: "18k,Yellow Gold", label: "18k Yellow Gold" },
    { value: "10k,Rose Gold", label: "10k Rose Gold" },
    { value: "14k,Rose Gold", label: "14k Rose Gold" },
    { value: "18k,Rose Gold", label: "18k Rose Gold" },
    { value: "925,Silver", label: "925 Silver" },
  ];

  const handleParityMetalColorChange = (e) => {
    setProduct({
      ...Product,
      parityMetalColor: e.value,
    });
  };

  const selectRhodiumPlayed = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const handleRhodiumPlayed = (e) => {
    setProduct({
      ...Product,
      rhodiumPlayed: e.value,
    });
  };

  const selectBestSeller = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const handleBestSeller = (e) => {
    setProduct({
      ...Product,
      bestSeller: e.value,
    });
  };

  const stoneColorOptions = [
    { value: "Beige", label: "Beige" },
    { value: "Black", label: "Black" },
    { value: "Blue", label: "Blue" },
    { value: "Brown", label: "Brown" },
    { value: "Clear", label: "Clear" },
    { value: "Gold", label: "Gold" },
    { value: "Gray", label: "Gray" },
    { value: "Green", label: "Green" },
    { value: "Ivory", label: "Ivory" },
    { value: "Multicolor", label: "Multicolor" },
    { value: "Orange", label: "Orange" },
    { value: "Pink", label: "Pink" },
    { value: "Purple", label: "Purple" },
    { value: "Red", label: "Red" },
    { value: "Silver", label: "White" },
    { value: "Yellow", label: "Yellow" },
  ];

  const handleStoneColorChange = (e) => {
    setProduct({
      ...Product,
      stoneColor: e.value,
    });
  };

  const mainStoneShapeOptions = [
    { value: "Asscher", label: "Asscher" },
    { value: "Baguette", label: "Baguette" },
    { value: "Baroque", label: "Baroque" },
    { value: "Brilliant Cut", label: "Brilliant Cut" },
    { value: "Briolette", label: "Briolette" },
    { value: "Butterfly", label: "Butterfly" },
    { value: "Cabochon", label: "Cabochon" },
    { value: "Clover", label: "Clover" },
    { value: "Cushion", label: "Cushion" },
    { value: "Emerald", label: "Emerald" },
    { value: "Heart", label: "Heart" },
    { value: "Hexagon", label: "Hexagon" },
    { value: "Marquise", label: "Marquise" },
    { value: "Oval", label: "Oval" },
    { value: "Pear", label: "Pear" },
    { value: "Princess", label: "Princess" },
    { value: "Radiant Cut", label: "Radiant Cut" },
    { value: "Rose Cut", label: "Rose Cut" },
    { value: "Round", label: "Round" },
    { value: "Square", label: "Square" },
    { value: "Star", label: "Star" },
    { value: "Teardrop", label: "Teardrop" },
    { value: "Asscher", label: "Asscher" },
  ];

  const handleMainStoneShapeChange = (e) => {
    setProduct({
      ...Product,
      mainStoneShape: e.value,
    });
  };

  useEffect(() => {
    if (redirect) {
      navigate("/products");
    }
    // eslint-disable-next-line
  }, [redirect]);

  useEffect(() => {
    if (matchRing.test(findProduct?.name)) {
      openModal();
    } else {
      closeModal();
      setSize(ringInitValue);
      // setSize(ringSizeInitialValue);
    }

    // eslint-disable-next-line
  }, [findProduct]);

  const handleEditProduct = (e) => {
    e.preventDefault();
    Product.description = quillValue;
    Product.ringsSizeAndQuantity = size;
    Product.photos = files;

    dispatch(updateProduct(Product?.id, Product));
  };

  const selectIsPublished = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleIsPublished = (e) => {
    setProduct({
      ...Product,
      isPublished: e.value,
    });
  };


  const selectIsFeatured = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleIsFeatured = (e) => {
    setProduct({
      ...Product,
      isFeatured: e.value,
    });
  };

  const selectMostSearched = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleMostSearched = (e) => {
    setProduct({
      ...Product,
      mostSearched: e.value,
    });
  };

  return (
    <>
      <div className="content-wrapper container-fluid">
        {Loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5 justify-content-center">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Edit Product</h5>
                </div>
                <div className="card-body">
                  <form method="POST">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Name"
                            name="name"
                            value={Product?.name}
                            onChange={handleInputChange}
                            readonly={true}
                            required={true}
                            type="text"
                            placeholder="Name here..."
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Category
                          </label>

                          <span className="d-flex gap-2">
                            <InputField
                              className="w-100"
                              options={selectCatgeoryOptions}
                              onChange={handleCategory}
                              value={Product?.category?.name}
                              readonly={true}
                            />
                            {matchRing.test(findProduct?.name) ||
                              matchRing.test(Product?.category?.name) ? (
                              <button
                                className="btn btn-primary px-2 animate__animated animate__bounceIn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  openModal();
                                }}
                              >
                                <GiBigDiamondRing className="fs-5" />
                              </button>
                            ) : null}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Sub Category
                          </label>
                          <InputField
                            options={subCatOP}
                            onChange={handleSubCategory}
                            readonly={true}
                            value={
                              subCategories.find((item) => {
                                return item?._id === Product?.subcategory;
                              })?.name
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Sku Number"
                            name="skuNumber"
                            value={Product?.skuNumber}
                            onChange={handleInputChange}
                            readonly={true}
                            type="text"
                            placeholder="Sku Number  here..."
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2">
                        <div className="mb-3">
                          <InputField
                            label="Tag Price"
                            name="price"
                            value={Product?.price}
                            onChange={handleInputChange}
                            required={true}
                            type="number"
                            placeholder="Price here..."
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="mb-3">
                          <InputField
                            label="Updated Tag Price"
                            name="price"
                            value={(Product?.price * 0.65).toFixed(2)}
                            onChange={handleInputChange}
                            required={true}
                            type="number"
                            readonly={true}
                            placeholder="Price here..."
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Tag
                          </label>
                          <InputField
                            options={selectTag}
                            onChange={handleTag}
                            value={
                              tags.find((i) => i?._id === Product?.tags)
                                ?.tagName
                            }
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <InputField
                                label="Stock"
                                name="stock"
                                value={Product?.stock}
                                onChange={handleInputChange}
                                readonly={true}
                                type="number"
                                placeholder="Stock here..."
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <InputField
                                label="Sale Percentage"
                                name="discount"
                                value={Product?.discount}
                                onChange={handleInputChange}
                                type="number"
                                placeholder="Percentage here EX: 5"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Reference"
                            name="reference"
                            value={Product?.reference}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Reference"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Specific References"
                            name="specificReferences"
                            value={Product?.specificReferences}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Specific References"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Metal Type
                          </label>
                          <InputField
                            options={selectMetalType}
                            onChange={handleMetalType}
                            value={Product?.metalType}
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Stone Type
                          </label>
                          <InputField
                            options={selectStoneType}
                            onChange={handleStoneType}
                            value={Product?.stoneType}
                            readonly={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Bar Code"
                            name="barCode"
                            value={Product?.barCode}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            required={true}
                            placeholder="Bar Code "
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname "
                          >
                            PURITY / METAL COLOR
                          </label>
                          <InputField
                            options={parityMetalColorOptions}
                            onChange={handleParityMetalColorChange}
                            value={Product?.parityMetalColor}
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Rhodium Plated
                          </label>
                          <InputField
                            options={selectRhodiumPlayed}
                            onChange={handleRhodiumPlayed}
                            value={Product?.rhodiumPlayed}
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Chain Length"
                            name="chainLength"
                            value={Product?.chainLength}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Chain Length"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Chain Type"
                            name="chainType"
                            value={Product?.chainType}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Chain Type"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Clasp"
                            name="clasp"
                            value={Product?.clasp}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Clasp"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Height"
                            name="pheight"
                            value={Product?.pheight}
                            onChange={handleInputChange}
                            readonly={true}
                            type="number"
                            placeholder="Height"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Width"
                            name="pwidth"
                            value={Product?.pwidth}
                            onChange={handleInputChange}
                            readonly={true}
                            type="number"
                            placeholder="Width"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Length"
                            name="plength"
                            value={Product?.plength}
                            onChange={handleInputChange}
                            readonly={true}
                            type="number"
                            placeholder="Length"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Backing"
                            name="backing"
                            value={Product?.backing}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Backing"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Minimum Cart Total Weight"
                            name="minCartTotalWeight"
                            value={Product?.minCartTotalWeight}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Min Cart Total Weight"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="average Color"
                            name="averageColor"
                            value={Product?.averageColor}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Average Color"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Side Stones"
                            name="condition"
                            value={Product?.condition}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Side Stones"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Average Clarity"
                            name="averageClarity"
                            value={Product?.averageClarity}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Average Clarity"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Setting Type"
                            name="settingType"
                            value={Product?.settingType}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Setting Type"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Number of Diamonds"
                            name="numberofDiamonds"
                            value={Product?.numberofDiamonds}
                            onChange={handleInputChange}
                            readonly={true}
                            type="number"
                            placeholder="Number of Diamonds"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Best Seller
                          </label>
                          <InputField
                            options={selectBestSeller}
                            onChange={handleBestSeller}
                            value={Product?.bestSeller}
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Center Stone"
                            name="centerStone"
                            value={Product?.centerStone}
                            onChange={handleInputChange}
                            readonly={true}
                            type="text"
                            placeholder="Center Stone"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Style Code"
                            name="styleCode"
                            value={Product?.styleCode}
                            onChange={handleInputChange}
                            readonly={true}
                            type="string"
                            placeholder="Style Code "
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Stone Color
                          </label>
                          <InputField
                            options={stoneColorOptions}
                            onChange={handleStoneColorChange}
                            value={Product?.stoneColor}
                            readonly={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Main Stone Shape
                          </label>
                          <InputField
                            options={mainStoneShapeOptions}
                            onChange={handleMainStoneShapeChange}
                            value={Product?.mainStoneShape}
                            readonly={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Published
                          </label>
                          <Select
                            options={selectIsPublished}
                            onChange={handleIsPublished}
                            value={
                              Product?.isPublished != null
                                ? selectIsPublished.find((option) => option.value === Product.isPublished)
                                : null
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Is Featured
                          </label>
                          <Select
                            options={selectIsFeatured}
                            onChange={handleIsFeatured}
                            value={
                              Product?.isFeatured === null
                                ? ""
                                : selectIsFeatured.find((option) => {
                                  return option.value === Product?.isFeatured;
                                })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Most Searched
                          </label>
                          <Select
                            options={selectMostSearched}
                            onChange={handleMostSearched}
                            value={
                              Product?.mostSearched === null
                                ? ""
                                : selectMostSearched.find((option) => {
                                  return option.value === Product?.mostSearched;
                                })
                            }
                          />
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Related Products
                          </label>
                          {/* <Select
                            options={selectRelatedProducts}
                            onChange={handleRelatedProducts}
                            value={
                              Product?.relatedProducts === null
                                ? ""
                                : selectRelatedProducts.find((option) => {
                                  return option.value === Product?.relatedProducts;
                                })
                            }
                            isMulti
                          /> */}
                          <Select
                            options={selectRelatedProducts}
                            onChange={handleRelatedProducts}
                            value={selectRelatedProducts?.filter((option) =>
                              Product?.relatedProducts?.includes(option.value)
                            )}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            className="form-label fs-4"
                            htmlFor="basic-default-fullname"
                          >
                            Description
                          </label>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `${quillValue}`,
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          {Product?.photos?.map((image, index) => (
                            <div key={index} className="col-md-2">
                              {image.split(".").pop() !== "mp4" ? (
                                <img
                                  src={image}
                                  alt={`Product ${index + 1}`}
                                  className="img-fluid"
                                />
                              ) : (
                                <video
                                  src={image}
                                  alt={`Video ${index + 1}`}
                                  autoPlay
                                  className="img-fluid"
                                  loop
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      onClick={handleEditProduct}
                      className="btn btn-primary"
                      readonly={fileLoading ? true : false}
                    >
                      Update Product
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="content-backdrop fade"></div>
        <div className="modal fade" ref={modalRef}>
          <div
            className="modal-dialog modal-dialog-centered backdrop-filter animate__animated animate__fadeIn animate__faster"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Rings</h5>
              </div>
              <div className="modal-body">
                <div className="container">
                  {size?.map((item, index) => {
                    return (
                      <div className="row my-3" key={index}>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="form-label required"
                              htmlFor="basic-default-fullname"
                            >
                              Ring Size
                            </label>
                            <InputField
                              options={selectRingSize}
                              onChange={(e) => handleRingSize(e, index)}
                              value={item.ringSize}
                              readonly={true}
                            />
                          </div>
                        </div>
                        <div className={`col-6`}>
                          <InputField
                            label="Quantity"
                            name="quantity"
                            placeholder="Quantity"
                            value={item.quantity}
                            readonly={true}
                            onChange={(e) => handleSizesChange(e, index)}
                          />
                        </div>
                        {/* {index > 0 && (
                          <div className="col-1 d-flex align-items-center">
                            <button
                              className="btn btn-danger p-2 m-auto mt-4"
                              onClick={() => removeFields(index)}
                            >
                              <i className="bx bx-trash "></i>
                            </button>
                          </div>
                        )} */}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    closeModal();
                    // setSize(ringSizeInitialValue);
                  }}
                >
                  Close
                </button>
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addFields}
                >
                  Add More Ring Size
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={closeModal}
                >
                  Save changes
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductEditForm;
