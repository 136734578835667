import { React, useEffect, useRef, useState } from "react";
import Select from "react-select";
import InputField from "../components/InputFields/InputField";
import PulseLoader from "../components/PulseLoader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import MultipleImageField from "../components/InputFields/MultipleImageField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "../utils/CustomModal";
import { GiBigDiamondRing } from "react-icons/gi";
import axios from "axios";

const { addProduct } = require("../actions/productActions");

const sizesInitialData = [
  {
    ringSize: "",
    quantity: "",
  },
];


const ProductAddForm = () => {
  const host = process.env.REACT_APP_API_HOST;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const Loading = false;
  const { categories } = useSelector((state) => state.Category);
  const [fileLoading, setFileLoading] = useState(false);
  const { subCategories } = useSelector((state) => state.SubCategory);
  const { tags } = useSelector((state) => state.Tag);
  const [subCatOP, setSubCatOP] = useState([]);
  const [quillValue, setQuillValue] = useState("");
  const { redirect } = useSelector((state) => state.Product);
  const [size, setSize] = useState(sizesInitialData);
  const [findProduct, setFindProduct] = useState(null);
  const { openModal, closeModal } = Modal(modalRef);
  const matchRing = /^(Rings|rings|Ring|ring)$/;
  const [files, setFiles] = useState([]);
  const [aiGenerated, setAiGenerated] = useState("");
  const [aiLoading, setAiLoading] = useState(false)
  const [Product, setProduct] = useState({
    name: "",
    category: "",
    subcategory: "",
    skuNumber: "",
    price: "",
    stock: "",
    reference: "",
    condition: "",
    specificReferences: "",
    description: "",
    metalType: "",
    stoneType: "",
    tags: "",
    styleCode: "",
    barCode: "",
    parityMetalColor: null,
    rhodiumPlayed: "",
    chainLength: "",
    chainType: "",
    clasp: "",
    ringSize: "",
    pheight: "",
    pwidth: "",
    plength: "",
    backing: "",
    minCartTotalWeight: "",
    averageColor: "",
    averageClarity: "",
    settingType: "",
    numberofDiamonds: "",
    photos: [],
    discount: "",
    bestSeller: "",
    centerStone: "",
    ringsSizeAndQuantity: [],
    stoneColor: null,
    mainStoneShape: null

  });

  useEffect(() => {
    setProduct({
      ...Product,
      photos: files,
    });
    // eslint-disable-next-line
  }, [files]);

  useEffect(() => {
    if (redirect) {
      navigate("/products");
    }
    // eslint-disable-next-line
  }, [redirect]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...Product, [name]: value });
  };

  const selectCatgeoryOptions = categories.map((category) => {
    return { value: category._id, label: category.name };
  });

  const handleCategory = (e) => {
    setProduct({
      ...Product,
      category: e.value,
      subcategory: null,
    });
    const subCat = subCategories.filter((subCategory) => {
      return subCategory.category._id === e.value;
    });

    const subCatOptions = subCat.map((subCategory) => {
      return { value: subCategory._id, label: subCategory.name };
    });
    setSubCatOP(subCatOptions);
  };

  const handleSubCategory = (e) => {
    setProduct({
      ...Product,
      subcategory: e.value,
    });
  };

  const selectRingSize = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
  ];

  const handleRingSize = (e, index) => {
    setSize((prevSizes) => {
      const newSizes = [...prevSizes];
      newSizes[index].ringSize = e.value;
      return newSizes;
    });
  };

  useEffect(() => {
    const subCat = subCategories.filter((subCategory) => {
      return subCategory.category._id === Product.category._id;
    });

    const subCatOptions = subCat.map((subCategory) => {
      return { value: subCategory._id, label: subCategory.name };
    });

    setSubCatOP(subCatOptions);

    // eslint-disable-next-line
  }, []);

  const selectTag = tags.map((tag) => {
    return { value: tag._id, label: tag.tagName };
  });

  const handleTag = (e) => {
    setProduct({
      ...Product,
      tags: e.value,
    });
  };

  const selectMetalType = [
    { value: "Gold", label: "Gold" },
    { value: "Silver", label: "Silver" },
    { value: "Fashion", label: "Fashion" },
  ];

  const handleMetalType = (e) => {
    setProduct({
      ...Product,
      metalType: e.value,
    });
  };

  const selectStoneType = [
    { value: "Natural Diamond", label: "Natural Diamond" },
    { value: "Simulated Diamond", label: "Simulated Diamond" },
    { value: "Lab Grown Diamond", label: "Lab Grown Diamond" },
    { value: "Moissanite", label: "Moissanite" },
    { value: "Cubic Zirconia", label: "Cubic Zirconia" },
    { value: "Gem Stones", label: "Gem Stones" },
  ];

  const handleStoneType = (e) => {
    setProduct({
      ...Product,
      stoneType: e.value,
    });
  };

  const stoneColorOptions = [
    { value: "Beige", label: "Beige" },
    { value: "Black", label: "Black" },
    { value: "Blue", label: "Blue" },
    { value: "Brown", label: "Brown" },
    { value: "Clear", label: "Clear" },
    { value: "Gold", label: "Gold" },
    { value: "Gray", label: "Gray" },
    { value: "Green", label: "Green" },
    { value: "Ivory", label: "Ivory" },
    { value: "Multicolor", label: "Multicolor" },
    { value: "Orange", label: "Orange" },
    { value: "Pink", label: "Pink" },
    { value: "Purple", label: "Purple" },
    { value: "Red", label: "Red" },
    { value: "Silver", label: "White" },
    { value: "Yellow", label: "Yellow" }
  ]

  const handleStoneColorChange = (e) => {
    setProduct({
      ...Product,
      stoneColor: e.value,
    });
  }

  const mainStoneShapeOptions = [
    { value: "Asscher", label: "Asscher" },
    { value: "Baguette", label: "Baguette" },
    { value: "Baroque", label: "Baroque" },
    { value: "Brilliant Cut", label: "Brilliant Cut" },
    { value: "Briolette", label: "Briolette" },
    { value: "Butterfly", label: "Butterfly" },
    { value: "Cabochon", label: "Cabochon" },
    { value: "Clover", label: "Clover" },
    { value: "Cushion", label: "Cushion" },
    { value: "Emerald", label: "Emerald" },
    { value: "Heart", label: "Heart" },
    { value: "Hexagon", label: "Hexagon" },
    { value: "Marquise", label: "Marquise" },
    { value: "Oval", label: "Oval" },
    { value: "Pear", label: "Pear" },
    { value: "Princess", label: "Princess" },
    { value: "Radiant Cut", label: "Radiant Cut" },
    { value: "Rose Cut", label: "Rose Cut" },
    { value: "Round", label: "Round" },
    { value: "Square", label: "Square" },
    { value: "Star", label: "Star" },
    { value: "Teardrop", label: "Teardrop" },
    { value: "Asscher", label: "Asscher" },
  ]

  const handleMainStoneShapeChange = (e) => {
    setProduct({
      ...Product,
      mainStoneShape: e.value
    })
  }

  const selectRhodiumPlayed = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const handleRhodiumPlayed = (e) => {
    setProduct({
      ...Product,
      rhodiumPlayed: e.value,
    });
  };

  const parityMetalColorOptions = [
    { value: "10k,White Gold", label: "10k White Gold" },
    { value: "14k,White Gold", label: "14k White Gold" },
    { value: "18k,White Gold", label: "18k White Gold" },
    { value: "10k,Yellow Gold", label: "10k Yellow Gold" },
    { value: "14k,Yellow Gold", label: "14k Yellow Gold" },
    { value: "18k,Yellow Gold", label: "18k Yellow Gold" },
    { value: "10k,Rose Gold", label: "10k Rose Gold" },
    { value: "14k,Rose Gold", label: "14k Rose Gold" },
    { value: "18k,Rose Gold", label: "18k Rose Gold" },
    { value: "925,Silver", label: "925 Silver" },
  ]

  const handleParityMetalColorChange = (e) => {
    setProduct({
      ...Product,
      parityMetalColor: e.value,
    });
  };

  const selectBestSeller = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const handleBestSeller = (e) => {
    setProduct({
      ...Product,
      bestSeller: e.value,
    });
  };

  const handleSizesChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...size];
    list[index][name] = value;
    setSize(list);
    setProduct({
      ...Product,
      ringsSizeAndQuantity: size,
    });
  };

  const addFields = () => {
    const lastIndex = size.length - 1;
    const lastItem = size[lastIndex];

    if (lastItem.quantity === "" || lastItem.ringSize === "") {
      toast.warning("Please fill the previous fields first!");
      return;
    }

    setSize([...size, { ringSize: "", quantity: "" }]);
  };

  useEffect(() => {
    if (matchRing.test(findProduct)) {
      openModal();
    } else {
      closeModal();
      setSize([
        {
          ringSize: "",
          quantity: "",
        },
      ]);
    }
    // eslint-disable-next-line
  }, [findProduct]);

  useEffect(() => {
    const findProduct = categories?.find((category) => {
      return category?._id === Product.category;
    });
    const findNameofCategory = findProduct?.name;
    setFindProduct(findNameofCategory);
    // eslint-disable-next-line
  }, [Product.category]);

  const handleAddProduct = (e) => {
    e.preventDefault();

    Product.description = quillValue;
    setProduct({
      ...Product,
      ringsSizeAndQuantity: size,
      itemDescription: quillValue,
    });

    if (
      Product.name !== "" &&
      Product.category !== "" &&
      Product.subcategory !== "" &&
      Product.price !== "" &&
      Product.metalType !== "" &&
      Product.tags !== "" &&
      Product.barCode !== ""
    ) {
      dispatch(addProduct(Product));
    } else {
      toast.error("Please fill all required fields");
    }
  };

  const handleAIGeneratedChange = (e) => {
    setAiGenerated(e.target.value);
  };

  const generateContentWithAi = async () => {
    setAiLoading(true);
    const { data } = await axios.post(
      `${host}/chat`,
      {
        prompt: aiGenerated,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("xat"),
        },
      }
    );
    setQuillValue(data.bot);
    setAiLoading(false);
  };

  return (
    <>
      <div className="content-wrapper container-fluid">
        {Loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5 justify-content-center">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Add Product</h5>
                </div>
                <div className="card-body">
                  <form method="POST">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Name"
                            name="name"
                            value={Product.name}
                            onChange={handleInputChange}
                            required={true}
                            type="text"
                            placeholder="Name here..."
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Category
                          </label>

                          <span className="d-flex gap-2">
                            <Select
                              className="w-100"
                              options={selectCatgeoryOptions}
                              onChange={handleCategory}
                              value={
                                Product.category === null
                                  ? ""
                                  : selectCatgeoryOptions.find((option) => {
                                    return (
                                      option.value === Product?.category?._id
                                    );
                                  })
                              }
                            />
                            {matchRing.test(findProduct) && (
                              <button
                                className="btn btn-primary px-2 animate__animated animate__bounceIn "
                                onClick={openModal}
                              >
                                <GiBigDiamondRing />
                              </button>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Sub Category
                          </label>
                          <Select
                            options={subCatOP}
                            onChange={handleSubCategory}
                            value={
                              Product.subcategory === null
                                ? ""
                                : subCatOP.find((option) => {
                                  return option.value === Product.subcategory;
                                })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Sku Number"
                            name="skuNumber"
                            value={Product.skuNumber}
                            onChange={handleInputChange}
                            type="text"
                            placeholder="Sku Number  here..."
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Tag Price"
                            name="price"
                            value={Product.price}
                            onChange={handleInputChange}
                            required={true}
                            type="number"
                            placeholder="Price here..."
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Tag
                          </label>
                          <Select
                            options={selectTag}
                            onChange={handleTag}
                            value={
                              Product.tags === null
                                ? ""
                                : selectTag.find((option) => {
                                  return option.value === Product.tags;
                                })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Stock"
                            name="stock"
                            value={Product.stock}
                            onChange={handleInputChange}
                            type="number"
                            placeholder="Stock here..."
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Sale Percentage"
                            name="discount"
                            value={Product.discount}
                            onChange={handleInputChange}
                            type="number"
                            placeholder="Percentage here EX: 5"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Reference"
                            name="reference"
                            value={Product.reference}
                            onChange={handleInputChange}
                            type="string"
                            placeholder="Reference"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Specific References"
                            name="specificReferences"
                            value={Product.specificReferences}
                            onChange={handleInputChange}
                            type="string"
                            placeholder="Specific References"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Metal Type
                          </label>
                          <Select
                            options={selectMetalType}
                            onChange={handleMetalType}
                            value={
                              Product.metalType === null
                                ? ""
                                : selectMetalType.find((option) => {
                                  return option.value === Product.metalType;
                                })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Stone Type
                          </label>
                          <Select
                            options={selectStoneType}
                            onChange={handleStoneType}
                            value={
                              Product.stoneType === null
                                ? ""
                                : selectStoneType.find((option) => {
                                  return option.value === Product.stoneType;
                                })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Bar Code"
                            name="barCode"
                            value={Product.barCode}
                            onChange={handleInputChange}
                            type="string"
                            required={true}
                            placeholder="Bar Code "
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname "
                          >
                            PURITY / METAL COLOR
                          </label>
                          <Select
                            options={parityMetalColorOptions}
                            onChange={handleParityMetalColorChange}
                            value={
                              Product.parityMetalColor === null
                                ? ""
                                : parityMetalColorOptions.find((option) => {
                                  return option.value === Product.parityMetalColor;
                                })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Rhodium Plated
                          </label>
                          <Select
                            options={selectRhodiumPlayed}
                            onChange={handleRhodiumPlayed}
                            value={
                              Product.rhodiumPlayed === null
                                ? ""
                                : selectRhodiumPlayed.find((option) => {
                                  return (
                                    option.value === Product.rhodiumPlayed
                                  );
                                })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Chain Length"
                            name="chainLength"
                            value={Product.chainLength}
                            onChange={handleInputChange}
                            type="number"
                            placeholder="Chain Length"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Chain Type"
                            name="chainType"
                            value={Product.chainType}
                            onChange={handleInputChange}
                            type="string"
                            placeholder="Chain Type"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Clasp"
                            name="clasp"
                            value={Product.clasp}
                            onChange={handleInputChange}
                            type="string"
                            placeholder="Clasp"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Height"
                            name="pheight"
                            value={Product.pheight}
                            onChange={handleInputChange}
                            type="number"
                            placeholder="Height"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Width"
                            name="pwidth"
                            value={Product.pwidth}
                            onChange={handleInputChange}
                            type="number"
                            placeholder="Width"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Length"
                            name="plength"
                            value={Product.plength}
                            onChange={handleInputChange}
                            type="number"
                            placeholder="Length"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Backing"
                            name="backing"
                            value={Product.backing}
                            onChange={handleInputChange}
                            type="string"
                            placeholder="Backing"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Minimum Cart Total Weight"
                            name="minCartTotalWeight"
                            value={Product.minCartTotalWeight}
                            onChange={handleInputChange}
                            type="number"
                            placeholder="Min Cart Total Weight"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="average Color"
                            name="averageColor"
                            value={Product.averageColor}
                            onChange={handleInputChange}
                            type="string"
                            placeholder="Average Color"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Side Stones"
                            name="condition"
                            value={Product.condition}
                            onChange={handleInputChange}
                            type="string"
                            placeholder="Side Stones"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Average Clarity"
                            name="averageClarity"
                            value={Product.averageClarity}
                            onChange={handleInputChange}
                            type="string"
                            placeholder="Average Clarity"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Setting Type"
                            name="settingType"
                            value={Product.settingType}
                            onChange={handleInputChange}
                            type="string"
                            placeholder="Setting Type"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <InputField
                            label="Number of Diamonds"
                            name="numberofDiamonds"
                            value={Product.numberofDiamonds}
                            onChange={handleInputChange}
                            type="number"
                            placeholder="Number of Diamonds"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Best Seller
                          </label>
                          <Select
                            options={selectBestSeller}
                            onChange={handleBestSeller}
                            value={
                              Product.bestSeller === null
                                ? ""
                                : selectBestSeller.find((option) => {
                                  return option.value === Product.bestSeller;
                                })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Center Stone"
                            name="centerStone"
                            value={Product.centerStone}
                            onChange={handleInputChange}
                            type="text"
                            placeholder="Center Stone"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <InputField
                            label="Style Code"
                            name="styleCode"
                            value={Product.styleCode}
                            onChange={handleInputChange}
                            type="string"
                            placeholder="Style Code "
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Stone Color
                          </label>
                          <Select
                            options={stoneColorOptions}
                            onChange={handleStoneColorChange}
                            value={
                              Product.stoneColor === null
                                ? ""
                                : stoneColorOptions.find((option) => {
                                  return option.value === Product.stoneColor;
                                })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            className="form-label required"
                            htmlFor="basic-default-fullname"
                          >
                            Main Stone Shape
                          </label>
                          <Select
                            options={mainStoneShapeOptions}
                            onChange={handleMainStoneShapeChange}
                            value={
                              Product.mainStoneShape === null
                                ? ""
                                : mainStoneShapeOptions.find((option) => {
                                  return option.value === Product.mainStoneShape;
                                })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="mb-3">
                          <InputField
                            label="Write product description with Techfye AI"
                            name="aiGenerated"
                            value={aiGenerated}
                            onChange={handleAIGeneratedChange}
                            type="text"
                            placeholder="Write Prompt here..."
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div style={{ marginTop: "30px" }}>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={generateContentWithAi}
                            disabled={aiLoading}
                          >
                            Generate with Techfye AI
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="basic-default-fullname"
                          >
                            Description
                          </label>
                          {/* <textarea
                            className="form-control"
                            name="description"
                            value={Product.description}
                            onChange={handleInputChange}
                            placeholder="Description here..."
                            rows={4}
                          /> */}
                          <ReactQuill
                            theme="snow"
                            value={quillValue}
                            onChange={setQuillValue}
                            placeholder={"Description here..."}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <MultipleImageField
                          name="photos"
                          setFiles={setFiles}
                          setFileLoading={setFileLoading}
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      onClick={handleAddProduct}
                      className="btn btn-primary"
                      disabled={fileLoading ? true : false}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="content-backdrop fade"></div>
        <div className="modal fade" ref={modalRef}>
          <div
            className="modal-dialog modal-dialog-centered backdrop-filter"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Rings</h5>
              </div>
              <div className="modal-body">
                <div className="container">
                  {size.map((item, index) => {
                    return (
                      <div className="row my-3" key={index}>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="form-label required"
                              htmlFor="basic-default-fullname"
                            >
                              Ring Size
                            </label>
                            <Select
                              options={selectRingSize}
                              onChange={(e) => handleRingSize(e, index)}
                              value={
                                item.ringSize === ""
                                  ? ""
                                  : selectRingSize.find((option) => {
                                    return option.value === item.ringSize;
                                  })
                              }
                            />
                          </div>
                        </div>
                        <div className={`col-${index === 0 ? "6" : "5"}`}>
                          <InputField
                            label="Quantity"
                            name="quantity"
                            placeholder="Quantity"
                            value={item.quantity}
                            onChange={(e) => handleSizesChange(e, index)}
                          />
                        </div>
                        {index > 0 && (
                          <div className="col-1 d-flex align-items-center">
                            <button
                              className="btn btn-danger p-2 m-auto mt-4"
                              onClick={() => {
                                setSize((prevSizes) => {
                                  const newSizes = [...prevSizes];
                                  newSizes.splice(index, 1);
                                  return newSizes;
                                });
                              }}
                            >
                              <i className="bx bx-trash "></i>
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    closeModal();
                    setSize(sizesInitialData);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addFields}
                >
                  Add More Ring Size
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={closeModal}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductAddForm;
