import { React, useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import PulseLoader from "../components/PulseLoader";
import { useSelector, useDispatch } from "react-redux";

const { setRedirecting } = require("../actions/productActions");

const Product = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.Product.products);
  const Loading = useSelector((state) => state.Product.loading);

  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [filterProduct, setFilterProduct] = useState([]);

  const handleChange = (e) => {
    setQuery(e.target.value);
    setSearchParams({ query: e.target.value });
  };

  dispatch(setRedirecting());
  useEffect(() => {
    if (query) {
      const newProducts = products.filter((product) => {
        return product.reference.toLowerCase().includes(query.toLowerCase());
      });
      setFilterProduct(newProducts);
    } else {
      setFilterProduct(products);
    }
  }, [query, products]);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="dropdown">
          <button
            type="button"
            className="btn p-0 dropdown-toggle hide-arrow"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="bx bx-dots-vertical-rounded"></i>
          </button>
          <div className="dropdown-menu">
            <Link
              className="dropdown-item"
              to={{
                pathname: `/products/edit-product/${row.id}`,
              }}
            >
              <i className="bx bx-edit-alt me-1"></i>Edit
            </Link>
          </div>
        </div>
      ),
      width: "100px",
    },
    {
      name: "Reference",
      selector: (row) => row.reference,
      sortable: true,
      width: "120px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      width: "150px",
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      width: "100px",
    },
    {
      name: "Stock",
      selector: (row) => row.stock,
      sortable: true,
      width: "100px",
    },
  ];

  const data = filterProduct.map((product) => {
    return {
      prodId: product._id,
      id: product.id,
      name: product.name,
      category: product.category ? product.category.name : "Uncategorized",
      price: product.price,
      stock: product.stock,
      reference: product.reference,
    };
  });

  // const csVDataDownload = products
  //   .filter((item) => item?.stock > 0 && item.price > 0.99 && item?.category?.slug !== "uncategorized" && item?.category?.slug !== "watches")
  //   .map((item) => {
  //     let categ = 0;

  //     if (item.category.slug === 'rings') {
  //       categ = 261994;
  //     } else if (item.category.slug === 'bands' || item.category.slug === "watches" || item.category.slug === "bracelet" || item.category.slug === "bangles") {
  //       categ = 31387;
  //     } else if (item.category.slug === 'earrings' || item.category.slug === 'earring') {
  //       categ = 261990;
  //     } else if (item.category.slug === 'pendants' || item.category === 'necklace') {
  //       categ = 261993
  //     } else if (item.category.slug === 'sets') {
  //       categ = 261976
  //     }

  //     let title = item.name;
  //     if (title.length > 80) {
  //       title = title.substring(0, 80);
  //       title = title.substring(0, title.lastIndexOf(' '));
  //     }

  //     const purity = item?.parityMetalColor?.split(",")[0];
  //     const metalColor = item?.parityMetalColor?.split(",")[1];
  //     let ringSize = '';

  //     if (item.category.slug === 'rings') {
  //       ringSize = item?.ringsSizeAndQuantity?.ringSize ? item?.ringsSizeAndQuantity?.ringSize : 0.5;
  //     }

  //     let necklaceLength = '';
  //     if (categ === 261993) {
  //       necklaceLength = item?.plength ? item?.plength : 'Unknown';
  //     }

  //     let bandWidth = '';
  //     let bandColor = '';
  //     let bandMaterial = '';
  //     let dialColor = '';
  //     if (categ === 31387) {
  //       bandWidth = "Unknown";
  //       bandColor = "Unknown";
  //       bandMaterial = "Unknown";
  //       dialColor = "Unknown";
  //     }

  //     let earArea = '';
  //     if (categ === 261990) {
  //       earArea = "Unknown";
  //     }

  //     return {
  //       "*Action(SiteID=US|Country=US|Currency=USD|Version=1193|CC=UTF-8)": "Add",

  //       "*Title": title,
  //       "*StartPrice": item.price * 3.5,
  //       "*Quantity": item.stock,
  //       "PicURL": item.images[0],
  //       "*ConditionID": "1000",
  //       "*Description": item?.description ? item.description : "No Description",
  //       "*Location": "USA Taxes",
  //       "ShippingService-1:Option": "UPSGround",
  //       "*DispatchTimeMax": 3,
  //       "*ReturnsAcceptedOption": "ReturnsAccepted",
  //       "ReturnsWithinOption": "Days_14",
  //       "ShippingCostPaidByOption": "Buyer",
  //       "*C:Brand": "Amiraca's Silver Collection",
  //       "*C:Main Stone": item.stoneType ? item.stoneType : "Unknown",
  //       "*C:Metal": item.metalType,
  //       "*C:Color": item.stoneColor,

  //       "*C:Ring Size": ringSize,

  //       "*C:Necklace Length": necklaceLength,

  //       "*C:Band Width": bandWidth,
  //       "*C:Band Material": bandMaterial,
  //       "*C:Band Color": bandColor,
  //       "*C:Dial Color": dialColor,

  //       "*C:Ear Area": earArea,

  //       "*Category": categ,
  //       "*C:Type": item.category.name,
  //       "*C:Metal Purity": purity ? purity : "Unknown",
  //       "*C:Main Stone Color": metalColor,
  //       "*C:Main Stone Shape": item.mainStoneShape,
  //       "*C:Department": 'Men, Unisex Adults, Women',
  //       "*C:Main Stone Creation": "Unknown",
  //       "*C:Setting Style": item.settingType,
  //       "*C:Diamond Color Grade": 'D',
  //       "*C:Total Carat Weight": item.minCartTotalWeight,
  //       "*C:Material": 'Unknown',
  //       "*C:Main Stone Treatment": "Unknown",
  //       "*C:Country of Origin": "USA",
  //       "*C:Certification": "Unknown",
  //       "*C:Cut Grade": "Unknown",
  //       "*C:Diamond Clarity Grade": "Unknown",
  //       "*C:Colored Diamond Intensity": "Unknown",
  //       "*C:Features": "Unknown",
  //       "*C:Customized": "No",
  //       "*C:Model": "Unknown",
  //       "*C:Style": "Unknown",
  //       "*C:Item Length": item.plength,
  //       "*C:Reference Number": item.barCode ? item.barCode : item.reference,
  //       "*C:Movement": "Unknown",
  //       // "*C:Case Color": "Unknown",
  //       // "*C:Case Material": "Unknown",
  //       // "*C:Display": "Unknown",
  //       // "*C:Water Resistance": "10 m (1 ATM)",
  //       "*C:Year Manufactured": 2023,
  //       "*C:With Papers": "No",
  //       "*C:With Original Box/Packaging": "No",
  //       "*Format": "FixedPrice",
  //       "*Duration": "GTC",
  //       "ShippingService-1:Cost": 0,
  //     };
  //   });

  // const csv = Papa.unparse(csVDataDownload);
  // const download = () => {
  //   const element = document.createElement("a");
  //   const file = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   file.text = "Info,Version=1.0.0,Template=fx_category_template_EBAY_US\n" + csv;
  //   element.href = URL.createObjectURL(file);
  //   element.download = `Ebay Export ${new Date().toLocaleDateString("en-GB", {
  //     day: "numeric",
  //     month: "short",
  //     year: "numeric",
  //   })}.csv`;
  //   document.body.appendChild(element);
  //   element.click();
  // };

  return (
    <>
      <div className="content-wrapper container">
        {Loading ? (
          <PulseLoader />
        ) : (
          <div className="row p-5">
            <div className="card">
              <div className="row">
                <div className="col-md-3">
                  <h5 className="card-header">All Products</h5>
                </div>
                <div className="col-md-5">
                  <input
                    type="text"
                    name="search"
                    onChange={handleChange}
                    value={query}
                    className="form-control my-3"
                    placeholder="Search Product By Reference"
                  />
                </div>
                {/* <div className="col-md-2 text-end">
                  <div className="">
                    <Link
                      to="/products/add-product"
                      className="btn btn-outline-primary my-4 btn-sm"
                    >
                      <span className="tf-icons bx bx-plus-circle"></span>&nbsp;
                      Add Product
                    </Link>
                  </div>
                </div> */}
                {/* <div className="col-md-2">
                  <button
                    className="btn btn-outline-primary my-4 btn-sm"
                    onClick={download}
                  >
                    Export for Ebay
                  </button>
                </div> */}
              </div>
              <div className="table-responsive text-nowrap">
                <DataTable
                  columns={columns}
                  data={data}
                  defaultSortAsc="true"
                  pagination
                  responsive="true"
                  paginationPerPage={50}
                />
              </div>
            </div>
          </div>
        )}

        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
};

export default Product;
