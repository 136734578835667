import axios from "axios";
import { toast } from "react-hot-toast";
const API_URL = process.env.REACT_APP_API_HOST;

export const getAllOrders = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "ORDERS_GET_ALL_REQUEST" });
      const { data } = await axios.get(`${API_URL}/orders`, {
        headers: {
          "x-auth-token": localStorage.getItem("xat"),
        },
      });
      dispatch({ type: "ORDERS_GET_ALL_SUCCESS", orders: data });
    } catch (error) {
      dispatch({ type: "ORDERS_GET_ALL_FAILURE", error: error.message });
    }
  };
};

export const loadOrders = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOAD_ORDERS_REQUEST" });
      dispatch({ type: "LOAD_ORDERS_SUCCESS", orders: data });
    } catch (error) {
      dispatch({ type: "LOAD_ORDERS_FAILURE", error: error.message });
    }
  };
};

export const updateOrderStatus = (id, status) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_ORDER_STATUS_REQUEST" });
      const { data } = await axios.put(
        `${API_URL}/orders/admin/${id}`,
        { status },
        {
          headers: {
            "x-auth-token": localStorage.getItem("xat"),
          },
        }
      );
      toast.success("Order status updated successfully");
      dispatch({ type: "UPDATE_ORDER_STATUS_SUCCESS", orders: data });
    } catch (error) {
      dispatch({ type: "UPDATE_ORDER_STATUS_FAILURE", error: error.message });
    }
  };
};
